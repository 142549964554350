import React from "react";
import "../css/Stablecoin.css";
import { Link } from "react-router-dom";
import Innovative from "./Innovative";

export default function Stablecoin() {
  return (
    <div>
      <div className="container stablecoin-container position-relative">
        <div className="">
          <div className="row spacer">
            <h4 className="h3 mb-3 text-center ">
              INRx Reservoir Collateralized Decentralized Stablecoin
            </h4>
            <div className="col-sm-12 col-md pt-3">
              <div className="card-ban">
                <div className="d-flex justify-content-center">
                  <img width={70} src="/img/card-img/1-01.svg" />
                </div>
                <h3 className="card-ban-text">Decentralized</h3>
                <p className=" stablecoin-para text-center">
                  The goal of the INRx protocol is to provide the blockchain
                  industry with a stablecoin solution that is irreversible,
                  decentralised, tamper-proof, and does not need freezing.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md pt-3">
              <div className="card-ban">
                <div className="d-flex justify-content-center">
                  <img width={70} src="/img/card-img/1-02.svg" />
                </div>
                <h3 className="card-ban-text">Stable Collateralization</h3>
                <p className="stablecoin-para text-center">
                  The INRx system is protected against market and price swings
                  in relation to the Indian Rupee by utilising proven stable
                  currencies as collateral, INR
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md pt-3">
              <div className="card-ban">
                <div className="d-flex justify-content-center">
                  <img width={70} src="/img/card-img/1-03.svg" />
                </div>
                <h3 className="card-ban-text">Multi-chain Engineering</h3>
                <p className="stablecoin-para text-center">
                  Pooling the INRx cryptocurrency over many networks with the
                  blockchain's most stable currency will ensure that the INRx
                  remains unaffected by market fluctuations.
                </p>
              </div>
            </div>
          </div>
          <div className="my-5">
            <Innovative />
          </div>

          <div className="usdd-exchange ">
            <div className="usdd-cont">
              <div className="title">Current Price Stability</div>
              <div className="desc mb-4">
                The INRx Foundation and the INRx  Foundation have come up with a
                joint depositing tool called Current Price Stability (CPS), with
                the intention of maintaining the value of the INRx Stable asset.
                Users have the ability to "Mint" INRx by depositing INR that is stable and does not experience price
                slippage.
              </div>
              <div className="usdd-btn mb-2">
                <Link to="/voting">
                  <button
                    type="button"
                    className="btn-hover color-4 py-2 ant-btn-- ant-btn-default-- exchange-- button-wrapper--"
                  >
                    Get Started
                  </button>
                </Link>
              </div>
              <img className="exchangeRight" src="/img/balance_1.gif" alt="" />
              <img
                className="exchangeRight"
                src="/img/exchange-bg.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
