import React, { useState, useEffect } from 'react'
import { Header } from './Header'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../css/Proposal.css';
import "../css/PsmSwap.css";
import "../css/Staking.css"

import { useSelector, useDispatch } from "react-redux";
import { AiFillCloseCircle } from 'react-icons/ai';
import { onConnect, voting } from '../helpers/getWeb3';
import { useNetwork } from '../helpers/useNetwork';

import { setConfig } from "../redux/reducer/user";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


export default function Proposal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.user.value);
  const { quill, quillRef } = useQuill();
  const [content, setTextArea] = useState("")
  const [areaPreview, setAreaPreview] = useState(false);

  const inputArr = [{ type: "text", id: 0, value: "" }];
  const [arr, setArr] = useState(inputArr);
  const [connection, setConnection] = useState(false);
  const [title, setTitle] = useState("");
  const [choice, setChoice] = useState("");
  const [startn, setStartDate] = useState("");
  const [endn, setEndDate] = useState("");
  const [choiceData, setChoiceData] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [nav, setNav] = useState(false);
  const [itemValue, setItemValue] = useState();
  const netw = useNetwork();
  const networks = netw[0];
  const choiceArr = {
    value: choice
  }
  const choiceDatan = [choiceArr, ...arr]
  // console.log(choiceData.split(","), "choice:::::DATA::::::NNNNNNNN")
  // console.log(choiceDatan, "choice:::::12345678::::::NNNNNNNN")

  // // const a = new Set(choiceData.split(",")).size == choiceData.split(",").length - 1;
  // console.log(a, "?????1");

  function checkIfDuplicateExists(choiceData) {
    return new Set(choiceData.split(",")).size !== choiceData.split(",").length
  }

  useEffect(() => {
    setItemValue(checkIfDuplicateExists(choiceData));
  }, [choiceData])

  useEffect(() => {
    if (nav === true) {
      navigate("/voting");
    }
  }, [nav]);

  const addInput = () => {
    setArr(s => {
      return [
        ...s,
        {
          type: "text",
          id: s.length,
          value: ""
        }
      ];
    });
  };


  const handleChange = e => {
    e.preventDefault();
    const index = e.target.id;
    setArr(s => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };
  const deleteChoise = (i) => {
    const data = arr.filter(item => item.id !== i)
    setArr(data);
  }

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        const text_area = quill.root.innerHTML;
        setTextArea(text_area);
        if (text_area.length > 1) {
          setAreaPreview(true);
        } else {
          setAreaPreview(false);
        }
      });
    }
  }, [quill]);
  // console.log(content,"content::::>>>>")
  // console.log(quill,"quill::::>>>>")


  useEffect(() => {

    const stringData = choiceDatan?.reduce((result, item) => {
      return `${result}${item.value},`
    }, "")
    setChoiceData(stringData)

    // console.log(stringData, "STRING::::::::::>>>>>>")

    const startDate = new Date(startn).getTime().toString();
    const endDate = new Date(endn).getTime().toString();
    // console.log(startDate, "START>>>>>>>>>>>")
    // console.log(endDate, "END:::>>>>>>>>>>>")
    setStart(startDate);
    setEnd(endDate);
  }, [choiceDatan, startn, endn])

// useEffect(()=>{
//   document.getElementById("manny").innerHTML= content ;

// },[content])
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm pb-5">
        <Header />
       <div className='page-content'>
       <div className="container">

       <h3 className='heading mb-4 text-center' >Make a <span className='span-gradient'></span>Proposal</h3>

          <div className='mb-5'>
            <div className='collateral_ratio'>
              <div className='row justify-content-center'>
               
                <div className='col-md-8 col-sm-12'>
                  <div className=''>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label text-white fs-5">Title</label>
                      <input type="text" className="form-control" id="exampleFormControlInput123" required placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div style={{ width: "100%", height: "auto" }}>
                      <div ref={quillRef} required />
                    </div>
                     {/* <div className='text-white form-control ' dangerouslySetInnerHTML={{__html: content}}></div> */}
                    {areaPreview && <div className='pt-5'>
                      <label for="exampleFormControlInput1" className="form-label text-white fs-5">Preview</label>
                      <div className="form-control text-white text-break"  dangerouslySetInnerHTML={{__html: content}}  placeholder="Preview" disabled rows="3"></div>
                    </div>}
                    <div className="mb-3 mt-3 bg-choices p-3">
                      <div className="form-label text-white fs-5 p-2">Choices</div>
                      <input type="text" className="form-control mt-2" placeholder="Enter Choice" required onChange={(e) => setChoice(e.target.value)} />
                      {arr && arr?.map((item, i) => {
                        {/* console.log(item?.value, "item.value 123") */ }
                        {/* setItemValue(item?.value) */ }
                        return (
                          <>
                            <div className='d-flex align-items-center position-relative mt-3'>
                              <input
                                onChange={handleChange}
                                value={item.value}
                                placeholder="Enter Choice"
                                required
                                id={i}
                                type={item.type}
                                className="form-control mt-3 " />
                              {i != 0 && <span onClick={() => { deleteChoise(i) }}><AiFillCloseCircle className='img-position position-absolute' size={20} color='white' /></span>}
                            </div>
                          </>
                        )
                      })}
                      <div className='d-inline-block connect-wallet mt-4' onClick={addInput}>ADD</div>
                    </div>

                  </div>
                </div>
                <div className='col-md-4 col-sm-12'>
                  <div className='action-border m-4'>
                    <div className='p-3 action-class text-center'>
                      Actions
                    </div>
                    <div className='action-body'>
                      <div className='p-3'>
                        <label for="exampleFormControlInput2" className="form-label text-white">Start Date</label>
                        <input type="date" className="form-control" min={new Date().toISOString().split('T')[0]} id="exampleFormControlInput2" required onChange={(e) => setStartDate((e.target.value)?.split("-"))} />
                      </div>
                      <div className='p-3'>
                        <label for="exampleFormControlInput3" className="form-label text-white">End Date</label>
                        <input type="date" className="form-control" min={new Date().toISOString().split('T')[0]} id="exampleFormControlInput3" required onChange={(e) => setEndDate((e.target.value)?.split("-"))} />
                      </div>
                      <div className='p-3'>
                        {config.user ? (<div className="connect-wallet p-1 text-center"
                          onClick={async (e) => {
                            if (title && content && choice && end && start !== "NaN" && content !== "" && itemValue === false) {
                              voting(networks, config?.web3, config?.user, title, content, choiceData, start, end, setNav)
                              setNav(false)
                            } else {
                              toast.error("Please Provide all details")
                            }
                          }}
                        >Submit</div>)
                          : (<div className="btn-hover color-7 w-100 text-center"
                            onClick={async (e) => {
                              onConnect(networks, setConnection).then(res => dispatch(setConfig({ config: res }))).catch(e => {
                                console.log("Error::", e);
                              })
                            }}
                          >Connect Wallet</div>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
    </>
  )
}
