import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/Innovative.css";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function Innovative() {
  const [barData, setBarData] = useState({});

  const { allData } = useSelector((state) => state.user.value);
  const value = {
    ana: (allData?.Tsupply_ana / allData?.ana) * 100,
    eth: (allData?.Tsupply_eth / allData?.eth) * 100,
    bnb: (allData?.bnb / allData?.Tsupply_bnb) * 100,
    polygon: (allData?.matic / allData?.Tsupply_matic) * 100,
    tron: (allData?.tron / allData?.Tsupply_tron) * 100,
  };

  useEffect(() => {
    setBarData(value);
  }, [allData]);

  const progress_data = [
    {
      id: "1",
      Name: "Analog",
      width:28,
      Logo: "/img/logo/analog_icon.svg",
      now: barData?.ana,
      label: `${barData?.ana?.toFixed(2)}%`,
    },
    {
      id: "2",
      Name: "Ethereum",
      width:18,
      Logo: "/img/erc1png.png",
      now: barData?.eth,
      label: `${barData?.eth?.toFixed(2)}%`,
    },
    {
      id: "3",
      Logo: "img/bsc.png",
      Name: "BNB",
      width:32,
      now: barData?.bnb,
      label: `${barData?.bnb?.toFixed(2)}%`,
    },
    {
      id: "4",
      Name: "Polygon",
      width:26,
      Logo: "/img/tabs_img/polygon.svg",
      now: barData?.polygon,
      label: `${barData?.polygon?.toFixed(2)}%`,
    },
    // {
    //   id: "5",
    //   Name: "Tron",
    //   width:26,
    //   Logo: "/img/tron-trx-logo.svg ",
    //   now: barData?.tron,
    //   label: `${barData?.tron?.toFixed(2)}%`,
    // },
  ];
  // console.log((allData?.bnb/(allData?.Tsupply_bnb) * 100) ,"BNB")
  return (
    <>
      <div className="">
        {/* <div className='pt-5'>
                    <div className='text_heading'>TRUSTED BY THE WORLD’S MOST INNOVATIVE COMPANIES</div>
                    <div>
                        <div className='row justify-content-center align-items-center pt-3'>
                            <div className=' col-sm-6 col-md text-center pt-2 pb-2'><div className='img-card-member bg-card-color'><img width={140} src='/img/logo/Analog_n.png'></img></div></div>
                            <div className=' col-sm-6 col-md text-center pt-2 pb-2'><div className='img-card-member bg-card-color'><img width={130} src='/img/logo/ETH_LOGO.png'></img></div></div>
                            <div className=' col-sm-6 col-md text-center pt-2 pb-2'><div className='img-card-member bg-card-color'><img width={110} src='/img/logo/BSC_N.png'></img></div></div>
                            <div className=' col-sm-6 col-md text-center pt-2 pb-2'><div className='img-card-member bg-card-color'><img width={100} src='/img/logo/polygon_n.svg'></img></div></div>
                            <div className=' col-sm-12 col-md text-center pt-2 pb-2'><div className='img-card-member bg-card-color'><img width={100} src='/img/logo/Tron_n.svg'></img></div></div>
                        </div>
                    </div>
                </div> */}
        <div className="usdd-exchange">
          <div className="collateral_ratio">
            <div className="text-h1">
              <div>Mint INRx with stable INR</div>
              <div> from Existing Blockchain, in minutes.</div>
            </div>
            <p className="text_para pt-3">
              INRx is stable crypto collateral backed fully decentralised stable
              INR. That is minted and controlled by its own community.
            </p>

            <div>
              <div className="row mt-3 ">
                <div className="col-md-6 col-12 minHeightcard ratio_card_order-2">
                  <div className="panel-txt-container-- card-card-- card-border-ui-- p-0 ratio_card">
                      {/* <h3 className="panel-heading">
                        Collateral Ratio Comparison
                      </h3>
                      <p className="text fs-14 mob-none">
                         INRx Tokens are a game-changer in the blockchain sector, supporting and empowering new businesses and innovations while also disrupting the existing financial system. The INRx Token is a digital asset that was developed on top of many different blockchain networks.
                      </p> */}

                      <div class="footer-wave">
                        <div class="waves">
                          <div class="wave" id="wave1"></div>
                          <div class="wave" id="wave2"></div>
                          <div class="wave" id="wave3"></div>
                          <div class="wave" id="wave4"></div>
                        </div>

                        <div class="text-center text-white h6">
                          Collateral Ratio Comparison                          
                        </div> 
                      </div>
                    <div>
                      {/*
                      <div className="position-relative mt-3 mb-4">                      
                          <div className="card-body minHeightcard">
                            <div class="ocean">
                              <div class="wave"></div>
                              <div class="wave"></div>
                              <div class="wave"></div>
                            </div>
                          </div>                      
                      </div> */}
                    {/* <div className="pt-5 mt-3">
                        {progress_data &&
                          progress_data?.map((e,i) => {
                            console.log(e.label,"e");
                            return (
                              <div key={i}>
                                <div className="d-flex align-items-center text-white justify-content-center" >
                                  <div className="  justify-content-center">
                                    <img
                                      width={e.width}
                                      src={e.Logo}
                                      alt=""
                                      //   style={{ height: "12px" }}
                                      className=" me-1"
                                    />
                                  </div>
                                  <div className=" w-75">
                                    <ProgressBar
                                      width={50}
                                      now={isNaN(e?.now)?0:e?.now}
                                      label={isNaN(e?.label)?'0%':e.label}
                                      className="ms-2 progress mt-3 mb-3"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div> */}
                    </div>
                
                  </div>
                </div>
                <div className="col-md-6 col-12 ratio_card_order-1">
                  <div className="panel-img-container--- card-card-- card-border-ui-- ">
                    <div>
                      <h3 className="finance_head">
                        Impact on the Financial Future
                      </h3>
                      {/* <p className="text fs-14">
                        INRx Tokens are a game-changer in the blockchain sector,
                        supporting and empowering new businesses and innovations
                        while also disrupting the existing financial system. The
                        INRx Token is a digital asset that was developed on top
                        of many different blockchain networks.
                      </p> */}
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src="/img/card-img/card4.png"
                          loading="lazy"
                          alt="Formation - Image"
                          className="finance_img"
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
