import React from "react";
import { Header } from "../Header";
import { Link } from "react-router-dom";
import Footer from "../Footer";

export default function AboutUS() {
  return (
    <>
      <div className="container">
        <div>
          <Header />
          <div className="page-content">
            <div className="text-center spacer">
              <div className="">
                <h3 className="spacer pt-0">
                  Welcome to the world of INRx, the Indian stable currency
                  designed to revolutionize the financial landscape by offering
                  a stable, secure, and efficient medium of exchange. INRx is a
                  cryptocurrency that is pegged to the Indian Rupee (INR),
                  providing stability and trust while leveraging the benefits of
                  blockchain technology. This page will delve into the various
                  aspects of INRx, including its features, benefits, underlying
                  technology, and potential impact on the economy.
                </h3>
                <h1 className="heading">
                  What is
                  <span className="span-gradient"> INRx </span>
                </h1>
                <p className="text-gray">
                  INRx is a digital currency that maintains a 1:1 peg with the
                  Indian Rupee, ensuring that one INRx is always equivalent to
                  one INR. This stability is achieved through a combination of
                  smart contracts, algorithmic controls, and collateralization,
                  which ensures that the value of INRx remains consistent with
                  the INR. INRx is built on a robust blockchain platform,
                  offering transparency, security, and immutability.
                </p>
              </div>
            </div>

            <div className="">
              <div class="row spacer justify-content-center">
                <h2 className="heading mb-4">
                  <span className="span-gradient">Features </span>of INRx
                </h2>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Stability
                      </h4>
                      <p className="text-gray fs-14">
                        The primary feature of INRx is its stability. Unlike
                        other cryptocurrencies that are subject to high
                        volatility, INRx maintains a stable value pegged to the
                        Indian Rupee. This makes it an ideal medium of exchange
                        and store of value, especially in the context of
                        everyday transactions and remittances.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Security
                      </h4>
                      <p className="text-gray fs-14">
                        INRx leverages the security features of blockchain
                        technology. Transactions are encrypted, and the
                        decentralized nature of the blockchain ensures that
                        there is no single point of failure. Additionally, all
                        transactions are recorded on an immutable ledger,
                        providing a transparent and tamper-proof history.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Efficiency
                      </h4>
                      <p className="text-gray fs-14">
                        With INRx, transactions can be completed in seconds,
                        regardless of the geographical location of the parties
                        involved. This is a significant improvement over
                        traditional banking systems, which can take days to
                        process cross-border transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Accessibility
                      </h4>
                      <p className="text-gray fs-14">
                        INRx aims to make financial services accessible to
                        everyone. By leveraging blockchain technology, it
                        provides a low-cost, efficient means of transacting,
                        especially for the unbanked and underbanked populations.
                        Users only need a smartphone and internet connection to
                        participate in the INRx ecosystem.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Transparency
                      </h4>
                      <p className="text-gray fs-14">
                        All transactions on the INRx blockchain are visible to
                        anyone, providing a level of transparency that is
                        unmatched by traditional financial systems. This
                        transparency helps in building trust among users and
                        ensures that the system operates fairly and efficiently.
                        How INRx Works
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Pegging Mechanism
                      </h4>
                      <p className="text-gray fs-14">
                        INRx maintains its peg to the Indian Rupee through a
                        combination of algorithmic controls and
                        collateralization. Smart contracts automatically adjust
                        the supply of INRx to match demand, ensuring that the
                        value remains stable. Additionally, reserves of INR or
                        other assets are held in collateral to back the value of
                        INRx.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card_outline ">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/checklist.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Blockchain Technology
                      </h4>
                      <p className="text-gray fs-14">
                        INRx is built on a state-of-the-art blockchain platform,
                        which provides the underlying infrastructure for
                        transactions. The blockchain ensures that all
                        transactions are secure, transparent, and immutable. The
                        use of smart contracts automates many of the processes
                        involved in issuing and redeeming INRx, reducing the
                        need for intermediaries and lowering costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row spacer">
                <h3 className="heading mb-3">
                  Use
                  <span className="span-gradient"> Cases </span>
                </h3>
                <div className="col-lg-6 mb-4">
                  <div class="custom_card_outline">
                    <div className="">
                      <h4 class="mb-2">
                        <img
                          src="/img/transaction.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Everyday Transactions
                      </h4>
                      <p className="text-gray fs-14">
                        INRx can be used for everyday transactions, just like
                        traditional fiat currency. From buying groceries to
                        paying utility bills, INRx offers a stable and efficient
                        means of payment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div class="custom_card_outline">
                    <div className="">
                      <h4 class="mb-2">
                        <img
                          src="/img/dollar-decrease.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Remittances
                      </h4>
                      <p className="text-gray fs-14">
                        One of the most significant use cases for INRx is in the
                        area of remittances. Sending money across borders can be
                        expensive and slow using traditional banking systems.
                        INRx provides a low-cost, fast alternative, making it
                        easier for individuals to send and receive money from
                        anywhere in the world. Savings and Investments
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div class="custom_card_outline">
                    <div className="">
                      <h4 class="mb-2">
                        {" "}
                        <img
                          src="/img/savings.svg"
                          alt=""
                          className="img_30 me-2"
                        />
                        Savings and Investments
                      </h4>
                      <p className="text-gray fs-14">
                        INRx also offers opportunities for savings and
                        investments. Users can hold INRx as a store of value,
                        benefiting from its stability and security.
                        Additionally, various financial products and services,
                        such as interest-bearing accounts and investment
                        opportunities, can be built on top of the INRx platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div class="custom_card_outline">
                    <div className="">
                      <h4 class="mb-2">
                        <img
                          src="/img/e-commerce.svg"
                          alt=""
                          className="img_30 me-2"
                        />{" "}
                        E-commerce
                      </h4>
                      <p className="text-gray fs-14">
                        E-commerce businesses can benefit from accepting INRx as
                        a payment method. It provides a low-cost, efficient
                        alternative to traditional payment methods, reducing
                        transaction fees and increasing accessibility for
                        customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row spacer">
                <h3 className="heading mb-4">
                  Benefits
                  <span className="span-gradient"> of INRx </span>
                </h3>
                <div className="col-lg-6 mb-4">
                  <h4>
                    {" "}
                    <img src="/img/people.svg" alt="" className="img_30 me-2" />
                    For Individuals
                  </h4>
                  <ul className="list">
                    <li>
                      Stability:
                      <span className="text-gray ms-1">
                        Users can transact and save with confidence, knowing
                        that the value of INRx is stable.
                      </span>
                    </li>
                    <li>
                      Security:
                      <span className="text-gray ms-1">
                        Blockchain technology ensures that transactions are
                        secure and tamper-proof.
                      </span>
                    </li>
                    <li>
                      Low Fees:
                      <span className="text-gray ms-1">
                        Transaction fees are significantly lower compared to
                        traditional banking systems.
                      </span>
                    </li>
                    <li>
                      Accessibility:
                      <span className="text-gray ms-1">
                        Anyone with a smartphone and internet connection can use
                        INRx, making financial services accessible to all.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-4">
                  <h4>
                    {" "}
                    <img
                      src="/img/business.svg"
                      alt=""
                      className="img_30 me-2"
                    />
                    For Businesses
                  </h4>
                  <ul className="list">
                    <li>
                      Reduced Costs:
                      <span className="text-gray ms-1">
                        Lower transaction fees and faster settlement times
                        reduce operational costs.
                      </span>
                    </li>
                    <li>
                      Increased Reach:{" "}
                      <span className="text-gray ms-1">
                        Businesses can reach a wider audience, including the
                        unbanked and underbanked populations.
                      </span>
                    </li>
                    <li>
                      Efficiency:
                      <span className="text-gray ms-1">
                        Automated processes reduce the need for intermediaries,
                        speeding up transactions and reducing costs.
                      </span>
                    </li>
                    <li>
                      Transparency:
                      <span className="text-gray ms-1">
                        Transparent transactions build trust with customers and
                        stakeholders.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-4">
                  <h4>
                    {" "}
                    <img
                      src="/img/economy.svg"
                      alt=""
                      className="img_30 me-2"
                    />
                    For the Economy
                  </h4>
                  <ul className="list">
                    <li>
                      Financial Inclusion:
                      <span className="text-gray ms-1">
                        INRx promotes financial inclusion by providing access to
                        financial services for everyone.
                      </span>
                    </li>
                    <li>
                      Reduced Inflation:{" "}
                      <span className="text-gray ms-1">
                        By providing a stable currency, INRx can help reduce
                        inflation and stabilize the economy.
                      </span>
                    </li>
                    <li>
                      Economic Growth
                      <span className="text-gray ms-1">
                        The efficiency and accessibility of INRx can drive
                        economic growth by facilitating trade and commerce.
                      </span>
                    </li>
                    <li>
                      Innovation:
                      <span className="text-gray ms-1">
                        The adoption of INRx can spur innovation in the
                        financial sector, leading to the development of new
                        products and services.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row align-items-center">
                <div className="spacer">
                  <h2 className="heading text-center">
                    <span className="span-gradient">The Future </span>of INRx
                  </h2>
                  <p className="text-center text-gray fs-14">
                    INRx is poised to play a significant role in the future of
                    finance in India and beyond. As more individuals and
                    businesses adopt INRx, it has the potential to transform the
                    financial landscape by providing a stable, secure, and
                    efficient medium of exchange. The ongoing development of the
                    INRx ecosystem will bring new features and capabilities,
                    further enhancing its utility and appeal.
                  </p>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Expansion Plans</h4>
                      <p className="text-gray fs-14">
                        INRx plans to expand its reach by partnering with
                        financial institutions, payment processors, and
                        businesses. These partnerships will help integrate INRx
                        into existing financial systems, making it easier for
                        users to transact with INRx.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Technological Advancements</h4>
                      <p className="text-gray fs-14">
                        The INRx team is committed to continuous improvement and
                        innovation. Future developments will focus on enhancing
                        the security, scalability, and functionality of the INRx
                        platform. This includes exploring new blockchain
                        technologies, developing additional financial products
                        and services, and improving the user experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Regulatory Compliance</h4>
                      <p className="text-gray fs-14">
                        INRx is committed to operating within the legal and
                        regulatory frameworks of the jurisdictions in which it
                        operates. The team works closely with regulators to
                        ensure compliance and to promote the responsible use of
                        cryptocurrency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="spacer">
              <div className=" w-container--">
                <h2 className="h3 text-center">
                  Some of the people who believe in us.
                </h2>
                <p class="text-center text-gray">
                  Our investors, team, and advisors include people from some of
                  the world’s leading organizations.
                  <br />
                </p>
                <div class="about-investors-logos">
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/61099e8c1fe2e46342a86bb3_firstbase-yc.svg"
                      loading="lazy"
                      alt="Logo of Y Combinator."
                      class="image-39"
                    />
                  </div>
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/63114939e2519fd73295fb87_CartaLogo_White.svg"
                      loading="lazy"
                      alt=""
                      class="image-39"
                    />
                  </div>
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/616080dcf2dfc742f18ff7e0_firstbase-notion.svg"
                      loading="lazy"
                      alt="Notion"
                      class="image-39"
                    />
                  </div>
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/6102cf5d9f8bc3a9889fabdf_firstbase-plaid.svg"
                      loading="lazy"
                      alt="Plaid"
                      class="image-39"
                    />
                  </div>
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/610813dfdcb08da7dd9461f7_firstbase-wise.svg"
                      loading="lazy"
                      alt="Wise"
                      class="image-39"
                    />
                  </div>
                  <div class="benefits-card">
                    <img
                      src="https://assets.website-files.com/609486d2fafa37698a57db5b/6123ba399d289c41616d39fc_firstbase-gusto.svg"
                      loading="lazy"
                      alt="Gusto"
                      class="image-39"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="above-footer">
          <div className="text-center">
            <div className="">
              <div className="call-to-action-section-- text-center">
                <div className="call-to-action-inner--">
                  <h3 class="h3">Work with us!</h3>
                  <p className="text-gray mb-3">
                    Make a career in blockchain web3 industry with INRx
                    Foundation
                  </p>

                  <div className="text-center">
                    <Link to="/careers" className="btn-hover color-7">
                      Go to Careers
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
