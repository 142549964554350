import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import "../css/Proposal.css";
import "../css/PsmSwap.css";
import "../css/Staking.css";
import "../css/Voting.css";
import { BsArrowRight, BsPatchCheckFill } from "react-icons/bs";
import { RiTimerFill } from "react-icons/ri";

import { IoIosPeople } from "react-icons/io";
import { MdHowToVote, MdOutlineDoDisturb } from "react-icons/md";
import { useNetwork } from "../helpers/useNetwork";
import { setConfig } from "../redux/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import { allData, modsAddress, onConnect } from "../helpers/getWeb3";
import moment from "moment/moment";

export default function Voting() {
  const dispatch = useDispatch();
  const [connection, setConnection] = useState(false);
  const { config } = useSelector((state) => state.user.value);
  const [active, setActive] = useState("Core");
  const [voteData, setVoteData] = useState([]);
  // const [vDate, setVdate] = useState([]);
  const [reportData, setReportData] = useState();
  const [closed, setClosed] = useState("option1");
  const [mods, setMods] = useState();
  // const [modsData, setModsData] = useState();
  const [abc, setABC] = useState();
  // const [modFil, setModFil] = useState([]);

  // console.log(reportData, "reportData::::::???????????????")
  // console.log(closed, "closed:::::::")
  // console.log(mods, "mods:::::mods::")

  const netw = useNetwork();
  const networks = netw[0];
  function tabActive(act) {
    setActive(act);
  }

  useEffect(() => {
    (async () => {
      // if (true) {
      onConnect(networks, setConnection)
        .then((res) => dispatch(setConfig({ config: res })))
        .catch((e) => {
          // console.log("Error::", e);
        });
      // }
    })();
    modsAddress(config.web3, networks).then((d) => {
      setMods(d.mod_Address);
    });
    // document.querySelector("#inlineRadio1").setAttribute("checked","true")
  }, [networks]);

  // useEffect(() => {
  //     mods && mods?.map((item, i) => {
  //         // console.log(item, "ITEM<<<<<<<")
  //         setModsData(item);
  //     })
  // }, [mods])

  useEffect(() => {
    if (config?.user) {
      allData(config?.web3, networks).then((d) => {
        setVoteData(d);
        // console.log(d, "D:::")
      });
    } else {
      // console.log("ERROR")
    }
  }, [config, networks]);
  useEffect(() => {
    // mods?.filter((item, i) => {
    //     // const arr=[]
    //     // arr.push(item)
    //     setModFil(item);
    // }
    // )

    setABC(
      voteData?.all_Data?.filter((obj) => {
        // console.log(mods, obj[6], "l")
        const b =
          active == "All"
            ? obj[6]
            : active == "Community"
            ? mods?.includes(obj[6]) ?? obj
            : active == "Core"
            ? !mods?.includes(obj[6]) ?? obj
            : "";
        return b;
      })
    );
  }, [mods, active, voteData]);
  // console.log(abc, "ABCCCCCCCCCCCCC?????????")
  // console.log(modFil, "modFil?????????")

  useEffect(() => {
    const abs = new Date().getTime();
    console.log(abc, "abc");
    if (abs !== null) {
      setReportData(
        abc?.filter((obj) => {
          const a =
            closed == "option3"
              ? Number(obj[5]) < Number(abs)
              : closed == "option2"
              ? Number(obj[4]) > Number(abs)
              : closed == "option1"
              ? Number(obj[4] <= Number(abs) && Number(obj[5]) >= Number(abs))
              : "";
          return a;
        })
      );
    }
  }, [voteData, closed, abc]);

  // console.log(voteData, "voteData")
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm pb-5">
        <Header />
        <div className="page-content">
          <div className="border-line--">
            <div className="voting-bg-- p-4">
              <div className="container">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="text-white pt-4">
                      <h1 className="heading">Voting</h1>
                      <p className="text-gray ">
                        Have your say in the future of the{" "}
                        <strong>INRx Ecosystem</strong>
                      </p>
                      <div className="pt-4">
                        <Link
                          to="/voting/proposal"
                          className="btn-hover color-7"
                        >
                          Make a Proposal
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="container spacer">
            <div className="">
              <h2 className="heading">Proposals</h2>
              <div className="mt-4">
                <div className="collateral_ratio">
                  <div className="">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={
                            active == "Core" ? "nav-link active" : "nav-link "
                          }
                          aria-current="page"
                          onClick={() => tabActive("Core")}
                        >
                          <BsPatchCheckFill /> Core
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            active == "Community"
                              ? "nav-link active"
                              : "nav-link "
                          }
                          onClick={() => tabActive("Community")}
                        >
                          <IoIosPeople size={25} /> Community
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            active == "All" ? "nav-link active" : "nav-link "
                          }
                          onClick={() => tabActive("All")}
                        >
                          All
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="">
                    <div className="py-3">
                      <div className="form-check form-check-inline text-vote">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          checked={closed == "option1" ? true : false}
                          onClick={(e) => setClosed(e.target.value)}
                        />
                        <label
                          className="form-check-label padding-lable"
                          for="inlineRadio1"
                        >
                          Vote Now
                        </label>
                      </div>
                      <div className="form-check form-check-inline text-vote">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          checked={closed == "option2" ? true : false}
                          onClick={(e) => setClosed(e.target.value)}
                        />
                        <label
                          className="form-check-label padding-lable"
                          for="inlineRadio2"
                        >
                          Soon
                        </label>
                      </div>
                      <div className="form-check form-check-inline text-vote">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          checked={closed == "option3" ? true : false}
                          onClick={(e) => setClosed(e.target.value)}
                        />
                        <label
                          className="form-check-label padding-lable"
                          for="inlineRadio3"
                        >
                          Closed
                        </label>
                      </div>
                    </div>

                    {reportData &&
                      reportData
                        ?.map((item, i) => {
                          const start = new Date(Number(item[4]));
                          const end = new Date(Number(item[5]));
                          {
                            /* const StartTime = Number(start);
                                        const EndTime = Number(end);
                                        const cTime = Number(new Date().getTime()); */
                          }

                          const StartTime = moment(Number(start)).format(
                            "DD-MMM-yyyy"
                          );
                          const EndTime = moment(Number(end)).format(
                            "DD-MMM-yyyy"
                          );
                          const cTime = moment(
                            Number(new Date().getTime())
                          ).format("DD MMM yyyy");

                          return (
                            <>
                              <div className="hoverclick position-relative">
                                <Link
                                  to={`/voting-proposal/make-a-voting?user=${
                                    mods?.includes(item[6])
                                      ? "Community"
                                      : "Core"
                                  }&&id=${item[0]}`}
                                >
                                  <div className="position-absolute icon">
                                    <BsArrowRight className="" size={30} />
                                  </div>

                                  <div className="p-3 bottom-border">
                                    <div className="text-design text-vote">
                                      {item[1]}
                                    </div>
                                    {/* <div> Id: 400</div> */}
                                    <div className="d-flex align-items-center p-1">
                                      <div className="me-2 text-date text-vote fw-bold">
                                        <span
                                          className="p-1 rounded"
                                          style={{ color: "#02c675" }}
                                        >
                                          Start
                                        </span>{" "}
                                        {StartTime}
                                      </div>
                                      <div className="ms-2 text-date text-vote fw-bold">
                                        <span className="text-danger  p-1 rounded">
                                          End
                                        </span>{" "}
                                        {EndTime}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center pt-2">
                                      <Link
                                        to={`/voting-proposal/make-a-voting?user=${
                                          mods?.includes(item[6])
                                            ? "Community"
                                            : "Core"
                                        }&&id=${item[0]}`}
                                      >
                                        {StartTime > cTime ? (
                                          <div className="btn-set-warning  me-1 fw-bold d-flex align-items-center">
                                            <RiTimerFill className="me-1" />
                                            Soon
                                          </div>
                                        ) : EndTime < cTime ? (
                                          <div className="btn-set-closed  me-1 fw-bold d-flex align-items-center">
                                            <MdOutlineDoDisturb className="me-1" />
                                            Closed
                                          </div>
                                        ) : StartTime <= cTime &&
                                          EndTime >= cTime ? (
                                          <div className="btn-set-green  me-1 fw-bold d-flex align-items-center">
                                            <MdHowToVote className="me-1" />
                                            Vote Now
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                      <div className="btn-border ms-1">
                                        <BsPatchCheckFill />{" "}
                                        {mods?.includes(item[6])
                                          ? "Community"
                                          : "Core"}
                                      </div>
                                      {/* <div className='btn-border ms-1'></div> */}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </>
                          );
                        })
                        .reverse()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
