import React, { useState, useEffect } from 'react'
import { BsArrowLeft, BsPatchCheckFill } from 'react-icons/bs';
import { MdHowToVote } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { Header } from './Header';
import "../css/Voting-proposal.css";
import { getDataByid, getVotingDetails, onConnect, toTalNumberOfVote, vote } from '../helpers/getWeb3';
import { useNetwork } from '../helpers/useNetwork';
import { setConfig } from "../redux/reducer/user";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import moment from 'moment/moment';


export default function VotingProposal() {
    const dispatch = useDispatch();
    const [connection, setConnection] = useState(false);
    const [getData, setGetData] = useState();
    const [votingDetails, setVotingDetails] = useState([]);
    const [selectedOption, setSelectOption] = useState("");
    const [choiceI, setChoiceI] = useState([]);
    const [element, setElement] = useState(0);
    const [ref, setRef] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const { config } = useSelector((state) => state.user.value);
    const netw = useNetwork();
    const networks = netw[0][3];
    const location = useLocation();
    const key = Number(location.search.slice(-4));
    const user = location?.search?.split("&&")[0]?.slice(6)
    // console.log(getData?.ProposalInfo[0], "GETDATA::1::");
    // console.log(location, "location")

    useEffect(() => {
        (async () => {
            // if (true) {
            onConnect(networks, setConnection).then(res => dispatch(setConfig({ config: res }))).catch(e => {
                // console.log("Error::", e);
            })
            // }
        })();

    }, [ref])
    useEffect(() => {
        const arr = getData?.ProposalInfo[0]?.choice?.split(",")
        setChoiceI(arr);
    }, [getData, ref])

    // console.log(getData, ":::::::???????????")

    useEffect(() => {
        if (config?.user) {
            getDataByid(config?.web3, networks, key)
                .then((d) => {
                    setGetData(d.get_Data)
                    // console.log(d.get_Data, "GETDATAID")
                });

            getVotingDetails(config?.web3, networks, key)
                .then((d) => {
                    setVotingDetails(d.get_voting)
                    // console.log(d.get_voting, "GET:VOTING:DETAILS")
                });
        } else {
            // console.log("ERROR")
        }
    }, [config, ref])

    useEffect(() => {
        (async () => {
            if (choiceI?.length > 0) {
                const res = await choiceI?.slice(0, choiceI?.length - 1).map(async (item, i) => {
                    const d = await toTalNumberOfVote(config?.web3, networks, key, item)
                    const obj = { text: item, totalvote: d ? d : 0 };
                    return obj;
                }
                );
                Promise.all(res).then((d) => {
                    setElement(d);
                    // console.log("mandsdsds::", d)
                })
            }
        })();

    }, [choiceI, ref]);
    useEffect(() => {
        if (getData) {
            const abs = new Date().getTime()
            const ctime = (moment(Number(abs)).format("DD MMM yyyy"))
            const StartTime = (moment(Number(getData[0][0][4])).format("DD MMM yyyy"))
            const EndTime = (moment(Number(getData[0][0][5])).format("DD MMM yyyy"))

            if (StartTime <= ctime && EndTime >= ctime) {
                setBtnDisabled(false)
            }
        }

    }, [getData])


    return (
        <>
            <div className="tron-usdd-wrapper bg_psm pb-5">
                <Header />
                <div className='container'>
                    <div className='text-white'>
                        <Link className='text-white' to="/voting">
                            <BsArrowLeft size={25} />
                            <span className='ms-1'> Back to Vote Overview </span>
                        </Link>
                    </div>
                    <div className='pt-5'>
                        <div className='row'>
                            <div className='col-md-8 col-sm-12'>
                                <div className='d-flex align-items-center pt-2'>
                                    <Link to="/voting-proposal"><div className='btn-set me-1 '><MdHowToVote /> Vote Now</div></Link>
                                    <div className='btn-border ms-1'><BsPatchCheckFill size={13} /> {user}</div>
                                    {/* <div className='ms-2 btn-border '> {btnDisabled? "true": "false"}</div> */}
                                </div>
                                <div className='pt-4 text-white'>
                                    <h1 className=''>{getData?.ProposalInfo[0].userProposalTitle}</h1>
                                    <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: getData?.ProposalInfo[0].userProposalContent }}></div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='row'>
                                <div className='col-md-8 col-sm-12 text-white pt-1'>
                                    <div className='border-all rounded-15-all'>
                                        <div className=''>
                                            <div className='bg-tab-head p-3 rounded-15 bottom-border fw-bold fs-4'>
                                                Cast your vote
                                            </div>
                                            <div className='p-4'>
                                                {getData && getData?.ProposalInfo[0].choice?.split(",").slice(0, getData?.ProposalInfo[0].choice?.split(",").length - 1)?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <div className='border-all rounded-15-all mt-2 p-3'>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id={i} value={item} onChange={(e) => { setSelectOption(e.target.value) }} />
                                                                    <label className="form-check-label" for="inlineRadio1">{item}</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                                }
                                                <div className='d-flex'>
                                                    <button className='connect-wallet btn mt-4' disabled={btnDisabled ? true : false} onClick={() => {
                                                        if (selectedOption === "") {
                                                            toast.error("Pleace Select Choice...")
                                                        } else {
                                                            vote(config.web3, networks, key, config.user, selectedOption, setRef);
                                                        }

                                                    }}>
                                                        Cast Vote
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12 text-white pt-1'>
                                    <div className='border-all rounded-15-all'>
                                        <div className=''>
                                            <div className='bg-tab-head p-3 rounded-15 bottom-border fw-bold fs-4'>
                                                Current Results
                                            </div>
                                            {element && element.map((item, i) => {
                                                {/* console.log(item, "ITEM"); */}
                                                {/* console.log(item?.totalvote, ".,.,.,.,.,.,.,") */}
                                                {/* console.log((item?.totalvote?.total_vote * 100) / votingDetails.length, "!@#:::::::") */}
                                                return (
                                                    <>
                                                        <div className='p-3'>
                                                            <label className="form-check-label">{item.text}</label>
                                                            <div className="progress1">
                                                                <div className="progress-bar1" role="progressbar" style={{ width: ((item.totalvote.total_vote * 100) / votingDetails.length) + "%" }} aria-valuenow={(item.totalvote.total_vote * 100) / votingDetails.length} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            {/* <ProgressBar now={(item.totalvote.total_vote * 100) / votingDetails.length} className="mt-1 mb-1 progressn progress-bar1 " /> */}
                                                            <div className='d-flex justify-content-between'>
                                                                <div>{item.totalvote.total_vote} Votes</div>
                                                                <div>{((item.totalvote.total_vote * 100) / votingDetails.length)?.toFixed(2)}%</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-8 col-sm-12 text-white'>
                                    <div className='border-all rounded-15-all mt-4'>
                                        <div className=''>
                                            <div className='bg-tab-head p-3 rounded-15 bottom-border fw-bold fs-4'>
                                                Votes({votingDetails?.length})
                                            </div>
                                            <div>
                                                {votingDetails && votingDetails?.map((item, i) => {

                                                    return (
                                                        <>
                                                            <div className='row mt-4 ps-4'>
                                                                <div className='col-6 text-center'>{window.innerWidth >= 600 ? item[0] : item[0].slice(0, 6) + "..." + item[0].slice(-6)}</div>
                                                                <div className='col-6 text-center'>{item[1]}</div>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
