import React from "react";
import { Routes, Route } from "react-router-dom";
import Banner from "./components/Banner";
import "./css/Banner.css";
import HistoryTrans from "./components/HistoryTrans";
import PsmSwap from "./components/PsmSwap";
import Tron from "./components/Tron";
// import SolanaSwap from "./components/SolanaSwap";
import Privacy_policy from "./components/Privacy_policy";
import { setAllData } from "./redux/reducer/user";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { usdLock } from "./helpers/getWeb3";
import { useNetwork } from "./helpers/useNetwork";
import LoadingOverlay from "react-loading-overlay";
import styled from "styled-components";
import Staking from "./components/Staking";
import Lottie from "lottie-react";
import INRx_loader from "./inrx_loader.json";
import Proposal from "./components/Proposal";
import Voting from "./components/Voting";
import VotingProposal from "./components/Voting-proposal";
import AboutUS from "./components/pages/AboutUS";
import Careers from "./components/pages/Careers";
import Terms_and_conditions from "./components/pages/Terms_and_conditions";
import BuyWithUpi from "./components/buyWithUpi";
// import  AuditPage  from "./components/pages/Audit";
// import Transparency from "./components/Transparency";
import TrancparencyPage from "./components/pages/transparency"
import BlackfundPolicy from "./components/BlackfundPolicy";
import Faq from "./components/faq";
import FeeDetails from "./components/FeeDetails";
import Migration from "./components/Migration";

const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(0, 0, 0, 1);
    ${
      "" /* -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -ms-filter: blur(8px);
  -o-filter: blur(8px);
  filter: blur(8px); */
    }
    ${"" /* filter: blur(10px); */}
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`;
export const Home = () => {
  const dispatch = useDispatch();
  const [tData, settData] = useState();
  const netw = useNetwork();
  const CurrentINRxPrice = netw[1];

  useEffect(() => {
    usdLock().then((res) => {
      settData(res);
    });
  }, []);
  console.log(tData,"TDATA");
  // const usdt_price = (tData?.usdt_price / 100)
  const usdt_price = CurrentINRxPrice;
  const Tsupply_ana = tData?.total_supply.total_supply_ana / 1e18;
  // const Tsupply_eth = tData?.total_supply.total_supply_eth / 1e18;
  const Tsupply_bnb = tData?.total_supply.total_supply_bnb / 1e2;
  const Tsupply_matic = tData?.total_supply.total_supply_matic / 1e2;
  // const Tsupply_tron = ((tData?.total_supply.total_supply_ana?.toString()) / 1e18);
  // console.log(Tsupply_bnb,Tsupply_matic,"BNB,POLY")
 
  const total_supply_all = Tsupply_ana?Tsupply_ana:0 + Tsupply_bnb + Tsupply_matic;

  const ana = (tData?.usdt_lock.usdt_locked_ANA / 1e18) * usdt_price;
  // const eth = (tData?.usdt_lock.usdt_locked_eth / 1e18) * usdt_price;
  const bnb = (tData?.usdt_lock.usdt_locked_bnb / 1e18) * usdt_price;
  const matic = (tData?.usdt_lock.usdt_locked_matic / 1e6) * usdt_price;
  // const tron = ((tData?.usdt_lock.usdt_locked_tron?.toString()) / 1e18) * usdt_price
  const usdt_Collateral_all = isNaN(ana)?0:ana +bnb + matic;
  

  const Ratio = (usdt_Collateral_all / total_supply_all) * 100;
  const lab = total_supply_all - usdt_Collateral_all;
  // console.log(usdt_Collateral_all,"USDT_COLLATERAL_ALL")
  // console.log(lab,"LAB_BNB")
  
  dispatch( 
    setAllData({
      allData: {
        total_supply_all,
        usdt_Collateral_all,
        Ratio,
        lab,
        usdt_price,
        Tsupply_ana,
        Tsupply_bnb,
        // Tsupply_eth,
        Tsupply_matic,
        ana,
        // eth,
        bnb,
        matic,
      },
    })
  );  

  return (
    <>
      {!tData ? (
        <StyledLoader
          active={!tData}
          spinner={
            <Lottie
              animationData={INRx_loader}
              style={{ width: 200 }}
              loop={true}
            />
          }
          // text='Loading your content...'
          className="position-fixed zindex"
        >
          <div style={{ filter: "blur(3px)" }}>
            <Routes>
              <Route path="/" element={<Banner />} />
              <Route exact path="/historyTrans" element={<HistoryTrans />} />
              <Route path="/privacy-policy" element={<Privacy_policy />} />
              {/* <Route path="/:network/cps" element={<PsmSwap />} /> */}
              <Route path="/cps-swap/tronSwap" element={<Tron />} />
              {/* <Route path="/solanaSwap" element={<SolanaSwap />}/> */}
              <Route path="/staking" element={<Staking />} />
              {/* <Route path="/:network" element={<Voting />} />
              <Route path="/voting/:network" element={<Proposal />} /> */}
              {/* <Route
                path="/voting-proposal/:network"
                element={<VotingProposal />}
              /> */}
              <Route path="/about" element={<AboutUS />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/terms-and-conditions" element={<Terms_and_conditions />} />
              <Route path="/faq" element={<Faq/>} />
              <Route path="/FeeDetails" element={<FeeDetails/>} />
              <Route path="/BlackfundPolicy" element={<BlackfundPolicy />} />
              <Route path="/migration" element={<Migration/>} />
            </Routes>
          </div>
        </StyledLoader>
      ) : (
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route exact path="/historyTrans" element={<HistoryTrans />} />
          <Route path="/privacy-policy" element={<Privacy_policy />} />
          {/* <Route path="/:network/cps" element={<PsmSwap />} /> */}
          <Route path="/cps-swap/tronSwap" element={<Tron />} />
          {/* <Route path="/solanaSwap" element={<SolanaSwap />}/> */}
          <Route path="/staking" element={<Staking />} />
          <Route path="/:network" element={<Voting />} />
          <Route path="/voting/:network" element={<Proposal />} />
          <Route
            path="/voting-proposal/:network"
            element={<VotingProposal />}
          />
          <Route path="/about" element={<AboutUS />} />
          {/* <Route path="/audit" element={<AuditPage />}/> */}
          
          <Route path="/trancparency" element={<TrancparencyPage/>}/>
          <Route path="/careers" element={<Careers />} />
          <Route path="/terms-and-conditions" element={<Terms_and_conditions />} />
          <Route path="/BlackfundPolicy" element={<BlackfundPolicy />} />
          <Route path="/faq" element={<Faq/>} />
           <Route path="/FeeDetails" element={<FeeDetails/>} />
           <Route path="/migration" element={<Migration/>} />

        </Routes>
      )}
    </>
  );
};
